import React from 'react';

import { Button } from '@components/Button';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const ThankyouForm: React.FC = () => {
  const { t } = useTranslation();
  const home = () => {
    localizedNavigate('/');
  };

  return (
    <div className="desktop:pl-25 mobile:pl-6 mobile:pr-6">
      <h1>{t('rejoin-form.title_thankyou', 'Thank you!')}</h1>
      <span className="text-sm">
        {t(
          'rejoin-form.thankyou-form',
          ' We’ll send this to clubs in your area so they can contact you directly.'
        )}
      </span>
      <div className="desktop:w-60 tablet:w-60">
        <Button
          full
          className="mb-6 desktop:mt-10 mobile:mt-6 tablet:mt-10"
          clickHandler={() => {
            home();
          }}
        >
          {t('rejoin-form-edit.go-to-homepage', 'Go to Homepage')}
        </Button>
      </div>
    </div>
  );
};

export default ThankyouForm;
